import React from 'react'

const Pagination = ({postsPerPage, totalPosts, paginate}) => {

    const pageNumbers  = []
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
         pageNumbers.push(i)
    }
  return (
        // la mejor forma de centrar
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:30}}>
            
            <ul className="pagination">
                {pageNumbers.map(number =>(
                <li key={number} className="page-item">
                    <a onClick={ ()=> paginate(number) } href="#!" className='page-link'>
                        {number}
                    </a>
                    </li>  
                ))}
            </ul>
            
        </div>

   
  )
}

export default Pagination