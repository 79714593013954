import React,{useState, useEffect} from 'react'
import axios from 'axios'
import "./styles.scss"
import carrito from '../../../src/assets/static/carrito-compras-white.svg'
import userImg from '../../../src/assets/static/usuario-dark.svg'	
import productImg from '../../../src/assets/static/caja-abierta.svg'


import b from '../../../src/assets/static/busqueda.svg'
import d from '../../../src/assets/static/documento.svg'
import e from '../../../src/assets/static/eliminar.svg'
import au from '../../../src/assets/static/aumentar.svg'
import dis from '../../../src/assets/static/disminuir.svg'
import Button from '../Button'
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import { margin } from '@mui/system'
import {Link} from 'react-router-dom';	


//const baseUrl = "https://api-marathon-crud.azurewebsites.net/users"
 //const baseUrl = "http://localhost:3001/users"


 
 const baseUrl = `${process.env.REACT_APP_API}getProductos` 
 const baseUrl_search = `${process.env.REACT_APP_API}searchProduct`




const UserInfoComponent = () => {

    // componente de busqueda y  trabajar con los datos que tenemos desde json placeholder
    //const datos = obtenerDatos()
    //console.log('🎁🎁🎁los datos recibidos son:🎁🎁🎁')
    //console.log(datos)

    // setear los hooks de useState
    const [users, setUsers] = useState([])  // se inicializa como un arreglo vacio 
    //  hooks de búsqueda
    const [search, setSearch] = useState (" ")  // como se refiere a los estados del input vamos a inicializarlo como una cadena vacia
    const URL = "https://jsonplaceholder.typicode.com/users"
    


  
  //renderizar la vista mediante una tabla que mostrará los datos 


  return (

    <div >
            <div className='d-flex align-items-center mt-150' >
                <img
                    src={userImg}
                    alt=''
                    style={{ width: '45px', height: '45px' }}
                    className='rounded-circle'
                />

                <div className='ms-3' >
                    <p className='fw-bold mb-1'>  {localStorage.getItem('idnombre') }  {localStorage.getItem('idapellido_paterno') } </p>
                    <p className='text-muted mb-0'>  {localStorage.getItem('idmail') }</p>
                   



                </div>
                </div>              
   
    </div>
  )
}

export default UserInfoComponent