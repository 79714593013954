import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import SearchComponent from '../../components/SearchComponent/SearchComponent'

import './styles.scss' 


const Punchout = props => {
  const [user, setUser] = useState({})


  
  // se llama cuando se realiza un cambio en el campo de texto que contiene el nombre de usuario

 
  
  return (
    <div className="punchout">
    <div>
   
     </div>
     <div>
     <SearchComponent/>
     </div>
    </div>
  )
}

const mapDispatchToProps = {
  setUser,
}

export default connect(null, mapDispatchToProps)(Punchout)
