import React from "react";
import { MDBContainer, MDBRow, MDBCol, MDBIcon} from "mdbreact";
import { MDBBtn } from 'mdb-react-ui-kit';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import TimePicker from '@mui/lab/TimePicker';

const RutaComponent = () => {
  return (  
    <div>
    <br></br>
    <br></br>
    <form >
        <MDBTable borderless >
    
          <MDBTableHead   >
            <tr >
              <th ><h5>ENTREGA </h5></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </MDBTableHead>


          <MDBTableBody>
            <tr>
              <td>
                  <label htmlFor="defaultFormContactNameEx" className="grey-text">
                        Fecha de entrega
                  </label>
                  <input
                    type="date"
                    id="defaultFormContactNameEx"
                    className="form-control"
                  />
              </td>
              <td>
                  <label htmlFor="defaultFormContactNameEx" className="grey-text">
                  Hora de Recepción del cliente
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td></td>
              <td></td>
            </tr>
           


           
            <tr >
              <td> <h5> FOLIOS </h5> </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                  <label htmlFor="selectDocument" className="grey-text">
                      Documento
                  </label>
                  <select id="selectDocument"
                    className="form-control">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option selected value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </select>

              </td>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                   Folio Factura
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"  
                    placeholder='Ej. PFR002098' 
                  />

              </td>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                   Folio Carta Porte
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='Ej. FTP005859' 
                  />

              </td>
              <td></td>
            </tr>

            <tr>
            <td>
                  <td> <label htmlFor="selectDocument" className="grey-text">
                      Estibable
                  </label>
                  <select id="selectDocument"
                    className="form-control">
                    <option value="si">si</option>
                    <option value="no">no</option>
                    <option selected value="no">no</option>
                    
                  </select> 
                  </td>
                  <td style={{paddingRight:'1rem', paddingBottom:'8rem'}}>    </td>
                  <td>
                <label htmlFor="selectDocument" className="grey-text" >
                      Frágil
                  </label>
                  <select id="selectDocument"
                    className="form-control">
                    <option value="si">si</option>
                    <option value="no">no</option>
                    <option selected value="no">no</option>
                    
                  </select>

                </td>
                 

              </td>
               
                <td></td>
                <td></td>

            </tr>

            <tr  style={{paddingRight:'1rem', paddingTop:'8rem'}}>
              <td><h5>DIRECCION DE ORIGEN </h5></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  RFC
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='Ej. FTP005859' 
                  />
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  Razón Social
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  Calle
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
                  No. Interior
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  No. Exterior
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
            </tr>

            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                 Colonia
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  Municipio
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
                  C.P.
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  
                    Estado
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
            </tr>



            <tr >
              <td  style={{paddingRight:'1rem', paddingTop:'3rem'}}><h5> DIRECCION DE ENTREGA </h5></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  RFC
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='Ej. FTP005859' 
                  />
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  Razón Social
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  Calle
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
                  No. Interior
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  No. Exterior
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
            </tr>

            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                 Colonia
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  Municipio
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
                  C.P.
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  
                    Estado
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
            </tr>


            <br></br>
            <tr >
              <td  style={{paddingRight:'1rem', paddingTop:'3rem'}}><h5>CONTACTOS</h5></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  CONTACTO 1
                  </label>
                
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  Nombre
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  Celular
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
                  Correo
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>

              </td>
            </tr>



                
          
            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  CONTACTO 2
                  </label>
                
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            

            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  Nombre
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  Celular
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
                  Correo
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>

              </td>
            </tr>







            <tr  >
              <td  style={{paddingRight:'1rem', paddingTop:'3rem'}}> <h5>INSTRUCCIONES ESPECIALES DE ENTREGA </h5></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            

            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  Instrucciones de Acceso 
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  Chaleco
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
                  Casco
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
                  Gafas
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
             
            </tr>

            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  Botas de seguridad
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                 Otro
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
        

              </td>
              <td>
              
              </td>
             
            </tr>


            
            <tr class="" >
              <td class="fw-normal mb-1"  style={{paddingRight:'1rem', paddingTop:'3rem'}}> <h5>CANTIDAD DE IMPRESIONES POR DOCUMENTO A ENTREGAR AL CLIENTE </h5></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            

            <tr>
              <td>
                  <label htmlFor="folioFactura" className="grey-text">
                  Factura
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
              <td>

                  <label htmlFor="folioFactura" className="grey-text">
                  OC del cliente
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>


              <label htmlFor="folioFactura" className="grey-text">
              Documento de acceso
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />

              </td>
              <td>
              <label htmlFor="folioFactura" className="grey-text">
              Otro
                  </label>
                  <input
                    type="text"
                    id="folioFactura"
                    className="form-control"
                    placeholder='' 
                  />
              </td>
             
            </tr>

            
            
            

           
          </MDBTableBody>
        
        </MDBTable>
    </form>

  
    </div>
    
    
  );
};

export default RutaComponent;