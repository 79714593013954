import React,{useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import "./styles.scss"
import carritosvg from '../../../src/assets/static/agregar-carrito.svg'
import b from '../../../src/assets/static/busqueda.svg'
import d from '../../../src/assets/static/documento.svg'
import carritoWhite from '../../../src/assets/static/carrito-white.svg'
import UserInfoComponent from '../UserInfoComponent/UserInfoComponent'
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Pagination from '../Pagination/Pagination'
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import logoM from '../../../src/assets/static/logo-M.svg'



//const baseUrl = "https://api-marathon-crud.azurewebsites.net/users"
 //const baseUrl = "http://localhost:3001/users"

/*
 const baseUrl = "http://localhost:3001/getProductos" 
 const baseUrl_search = "http://localhost:3001/searchProduct"
 const baseUrl_addProduct = "http://localhost:3001/addProduct"
 const baseURL_searchOrdenCompra = "http://localhost:3001/searchOrdenCompra"
 const baseUrl_showCart = "http://localhost:3001/showCart"
*/

const URL_SERVER = "https://api-marathon-crud.azurewebsites.net/"





 const baseUrl = `${process.env.REACT_APP_API}getProductos` 
 const baseUrl_search = `${process.env.REACT_APP_API}searchProduct`
 const baseUrl_addProduct = `${process.env.REACT_APP_API}addProduct`
 const baseURL_searchOrdenCompra = `${process.env.REACT_APP_API}searchOrdenCompra`
 const baseUrl_showCart = `${process.env.REACT_APP_API}showCart`
 
 //url: `${process.env.REACT_APP_API}${endPoint}`,

 let mostrarBoton = false
 let mostrarAlertAdd = false
 let buscnado = false

  
const SearchComponent = (props) => {
/* paso de parametros */
  const { loggedIn } = props
  const { id,idpasswd } = useParams()

 



  console.log (` idid: ${localStorage.getItem ('idid')}`)
  console.log (`idusername: ${localStorage.getItem('idusername')}`)  
  console.log (` idpassword: ${localStorage.getItem('idpassword')}`)
  console.log (` idtoken: ${localStorage.getItem('idtoken')}`)
  console.log (`idnombre: ${localStorage.getItem('idnombre')}`)
  console.log (` idapellido_paterno ${localStorage.getItem('idapellido_paterno')}`)
  console.log (` idapellido_materno ${localStorage.getItem('idapellido_materno')}`)
  console.log (` idrole ${localStorage.getItem('idrole')}`)
  console.log (` idrole ${localStorage.getItem('idrole')}`)
  console.log (` idBranchCD ${localStorage.getItem('idBranchCD')}`)
  console.log (` iddepartment ${localStorage.getItem('iddepartment')}`)
  console.log (` idmail ${localStorage.getItem('idmail')}`)
  console.log (` idusr_punchout ${localStorage.getItem('idusr_punchout')}`)
  console.log (` idnombre_empresa ${localStorage.getItem('idnombre_empresa')}`)
  console.log (` idmoneda_monto_min ${localStorage.getItem('idmoneda_monto_min')}`)
  console.log (` idmonto_min_compra ${localStorage.getItem('idmonto_min_compra')}`)
  console.log (` idnombre_moneda ${localStorage.getItem('idnombre_moneda')}`)












  //const { match: { params } } = props;
  console.log ("🌕🌓🌔🌒🌕🌘🌕🌕🌕🌔🌗🌘🌒")
 /* console.log ("El valor de params es: ")
  console.log(params.id) // mostraria 9999*/
  console.log(id)
  console.log(idpasswd)

  console.log ("🌕🌓🌔🌒🌕🌘🌕🌕🌕🌔🌗🌘🌒")
 

  
  console.log(" 🥞🥞🥞 Informacion de loggedIn 🥞🥞🥞")
  console.log(loggedIn)
  console.log("🍇🍇🍇🍇 User Logged In:")
  console.log(localStorage.getItem('id'),)

    // componente de busqueda y  trabajar con los datos que tenemos desde json placeholder
    //const datos = obtenerDatos()
    //console.log('🎁🎁🎁los datos recibidos son:🎁🎁🎁')
    //console.log(datos)

    // setear los hooks de useState

    const [items, setItems] = useState([])  // se inicializa como un arreglo vacio 
    const [totalItemsFound, setTotalItemsFound] = useState(0)
    const [post, setPosts] = useState([]) 
    const [loading,setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState (1)
    const [postsPerPage] = useState (10)
    const [totalItemsCart, setTotalItemsCart] = useState(0)



    //  hooks de búsqueda
    const [search, setSearch] = useState ("")  // como se refiere a los estados del input vamos a inicializarlo como una cadena vacia
    const URL = "https://jsonplaceholder.typicode.com/users"
    

    //addCatalogo()

    function Items({ currentItems }) {
      return (
        <div>
          {currentItems &&
            currentItems.map((item) => (
              <div>
                <h3>Item #{item}</h3>
              </div>
            ))}
        </div>
      );
    }


    //funcion para traer los datos 
    const showData = async (dato) => {
      
      console.log('Volvemos a hacer la consulta !!!! 🥩🥩🥩🥩') 
      console.log(`El dato a buscar es ${dato}`)
      if (!dato) {
        console.log('El dato a buscar esta vacio 🍚🍚🍚')
        setItems([])
      } else{        
        try {         
            //localStorage.setItem('password', password)
            const res = await axios.get (baseUrl_search, {params:{producto:dato}})        
                console.log ("Llegue hasta aqui 👲👲👲")
                console.log ("Datos recibidos")
                console.log (res.data)      
                console.log ("token ")
                console.log (res.data[0].token)
                console.log ("user ")
                console.log (res.data[0].username)
                console.log ("name")
                console.log (res.data[0].nombre)       
                console.log ("id")
                console.log (res.data[0].id)        
                console.log ("role")
                console.log (res.data[0].role)
                console.log ("BranchCD")
                console.log (res.data[0].branch_id)
                console.log ("department")
                console.log (res.data[0].department)
                localStorage.setItem('codigoProducto',res.data[0].idproductos)
                localStorage.setItem('cantidadroducto', 1)
                localStorage.setItem('precioProducto',res.data[0].precio)
                     
                console.log (" 🌼🌼🌷Vamos a devolver los siguientes datos: 🌷🌼🌼")
                console.log (res.data)
                mostrarBoton=true
                setTotalItemsFound(res.data.length)
                console.log ("🚧🚧🚧🚧Total de datos encontrados 🚧🚧🚧🚧🚧")
                console.log (res.data.length)
                setCurrentPage(1)
                setLoading(true)
                mostrarAlertAdd=false
                
                console.log ("El valor de mostrarBoton es")
                console.log (mostrarBoton)

                setItems(res.data)
                //setUsers(response)
                
              } catch (error) {
                console.log (error)
                console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
              }
      }  
    }

   //funcion para traer los datos 
   const getSizeCart = async () => {
    const dato=localStorage.getItem('catalogoId')    
    console.log('Vamos a obtener el tamaño del carrito!!!!') 
  
      try {
       
            const res = await axios.get (baseUrl_showCart, {params:{idcatalogo:dato}})

                  
            console.log ("🎀🎀🎀 Llegue hasta aqui 🎀🎀🎀")
            console.log (" 🎀🎀🎀 EL tamaño del carrito de compras es 🎀🎀🎀")
            console.log (res.data.length) 
            console.log (" 🎀🎀🎀 Datos recibidos 🎀🎀🎀")
            console.log (res.data) 
            setTotalItemsCart(res.data.length)                                     
            } catch (error) {
              console.log (error)
              console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
            }
      
  }


    //funcion para traer los datos 



     const addProduct = async (dato1, dato2) => {
      console.log('Volvemos a hacer la consulta !!!! 🌷🌷🌷') 
      console.log(`El dato a buscar es ${dato1}`)

      //obtenemos los datos recibidos por la URL
      const catalogoId = localStorage.getItem('catalogoId')
      const productoId = localStorage.getItem('productoId')
      const cantidadProducto = localStorage.getItem('cantidadroducto')
      const precioProducto = localStorage.getItem('precioProducto')
      const totalProducto = cantidadProducto * precioProducto

     if (!dato1) {
       console.log('El dato a buscar esta vacio ☘☘☘')
       setItems([])
      // setMostrarDatos(1)

     } else{
      
       try {
           //localStorage.setItem('password', password)
            console.log(" 👶El id del producto a insertar es:")
            console.log(productoId)
           const res = await axios.get (baseUrl_addProduct , {params:{catalogo:catalogoId, codigo:productoId, cantidad:cantidadProducto, total:totalProducto,}})
       
               console.log ("Llegue hasta aqui 🍒🍒🍒")
               console.log ("Datos recibidos")
               console.log (res.data)      
               console.log ("token ")
               console.log (res.data[0].token)
               console.log ("user ")
               console.log (res.data[0].username)
               console.log ("name")
               console.log (res.data[0].nombre)       
               console.log ("id")
               console.log (res.data[0].id)        
               console.log ("role")
               console.log (res.data[0].role)
               console.log ("BranchCD")
               console.log (res.data[0].branch_id)
               console.log ("department")
               console.log (res.data[0].department)            
               console.log (" ⚽⚽⚽Vamos a devolver los siguientes datos: ⚽⚽⚽")
               console.log (res.data)
              mostrarAlertAdd = true
               setItems(res.data)
               //setUsers(response)
               
             } catch (error) {
               console.log (error)
               console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
             }
     }  
   }

    const searcher = (e) => {
      setSearch(e.target.value )
      console.log (e.target.value)
      //SearchComponent()
      showData(e.target.value)
     } 

     const clicker = (e,arg1,arg2) => {
        setSearch(e.target.value )
        console.log ("Se dio Click en el boton!!!")
        console.log (e.target)
        console.log(`${arg1}`);
        localStorage.setItem('productoId',arg1) 
        localStorage.setItem('precioProducto',arg2) 
        mostrarAlertAdd = true
        addProduct(arg1,arg2)
        getSizeCart()
     } 
 
     const deleteProductEvent = (e,arg1) => {
      setSearch(e.target.value )
      console.log ("Se dio Click en el boton Eliminar!!!")
      console.log (e.target)
      console.log(`${arg1}`);
      localStorage.setItem('productoId',arg1) 
      addProduct(arg1)
     } 
 

    // método de filtado 
  
    //funcion de búsqueda

    useEffect (() => {
      
      getSizeCart()
      showData()
      
    }, [])
  //renderizar la vista mediante una tabla que mostrará los datos 


  const muestraBoton = (item) => {
   
    console.log("Entramos a muestra boton 🎨🎨🎨🧶")
    if (mostrarBoton) {
      console.log("El valor de mostrarBoton es true 🎨🎨🎨🧶")
    return (
      <button 
            className="btn btn-danger btn-sm"                                    
            onClick={(event)=>clicker(event, item.idproductos, item.precio)}
          >                                     
          <img src={carritoWhite}  style={{ width: 15, height: 15 }} alt="" />   Agregar
      </button>
    )
   }
    console.log("El valor de mostrarBoton es false 🎨🎨🎨🧶")
    return (true)  
  }

  
  const muestraCantidadEncontrada = (size) => {
   
    console.log("Entramos a muestra boton 🎨🎨🎨🧶")
    if (mostrarBoton) {
      console.log("El valor de mostrarBoton es true 🎨🎨🎨🧶")
    return (
      <center><p style={{marginTop:50}}> Total de productos encontrados: {size} </p></center>
    )
   }
    console.log("El valor de mostrarBoton es false 🎨🎨🎨🧶")
    return (true)  
  }




  const muestraAlert= () => {
   
    console.log(" 🌴🌴🌴Entramos a muestra Alert 🌴🌴🌴")
    if (mostrarAlertAdd) {
      console.log("El valor de mostrarAlertAdd es true 🌴🌴🌴")
      return (
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
            Se agregó un producto nuevo al carrito 
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      )
   }
    console.log("El valor de mostrarBoton es false 🌴🌴🌴")
    return (true)  
  }



  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentItems  = items.slice(indexOfFirstPost, indexOfLastPost)

  // change page 
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  const TableProducts = ({post, loading,muestraboton}) => {

    console.log("Entramos a TablePagination 👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️")
    console.log("items - posts ")
    console.log(post)
    if (loading) {
        return (
            <div className='table-responsive'>
            <table  className="table table-striped table-hover mt-5 shadow-lg">
                <thead>
                    <tr className='bg-curso text-white'>
                        <th>Código</th>                    
                        <th>Descripción del producto </th>  
                        <th>Marca</th>
                        <th>Precio</th>
                        <th>Stock</th>
                        <th>Moneda</th>
                        <th>UM</th>
                        <th>Agregar</th>
                    </tr>
                </thead>

                <tbody>
                    { console.log("🛬🛬🛬🛬🛬🛬🛬🛬🛬🛬🛬")}
                    { console.log(post) }
                    {post.map ( (item) => (
                        <tr key={item.idproductos}>
                            <td> {item.codigo} </td>
                            <td> {item.descripcion} </td>
                            <td> {item.nombre_marca} </td>
                            <td> {item.precio} </td>
                            <td> {item.stock} </td>
                            <td> {item.nombre_moneda} </td>
                            <td> {item.nombre_medida} </td>
                            <td> <center>                              
                            {muestraBoton(item)}                              
                             </center></td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
        ) 

    }
  return (
    
   true

  )
}



  return (
    <div style={{marginLeft: "0rem", marginRight:"0rem"}}>     


        <UserInfoComponent/>
        <div style={{ textAlign:'right' }} >         
          <a title="Los Tejos" href= {process.env.REACT_APP_WEB+"carrito"}><img src={carritosvg} alt="carrito de compras" style={{ width: 30, height: 30 }} />
          <span className="badge bg-primary rounded-pill"> {totalItemsCart}</span>
          </a>
        </div>

        <center><img src={d}  style={{ width: 50, height: 50 }} alt="" /></center>

        <h1> <center>  Catálogo de productos</center> </h1>
        <center> <p>MARATHON</p> </center>
        <center> <p> Orden de Compra: { localStorage.getItem('catalogoId') } </p> </center>

        {muestraAlert()}
  
        <br></br>
        <center><h5> <img src={b}  style={{ width: 20, height: 20 }} alt="" /> Buscar producto </h5></center>
        
        <input value={search} 
               onChange={searcher} 
               type="text" 
               placeholder='Puede buscar por código, descripción o marca' 
               className='form-control'
        />

        {muestraCantidadEncontrada(items.length)}
              
        <div className='container mt-5' style={{marginRight:100 }} >   
          {console.log("Los items a mostrar son:") }       
          {console.log(items) }
          {console.log("👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️👷‍♂️")}
          {console.log("Current items")}
          {console.log(currentItems)}
          <TableProducts post={currentItems} loading={loading}/>
          <Pagination postsPerPage={postsPerPage} totalPosts={items.length} paginate={paginate} />
        </div>


        <div style={{textAlign:'center', marginLeft:'0rem', paddingRight:'0rem', paddingTop:'2rem', marginTop:'0rem'}}>
        <MDBTable align='middle'>
            <MDBTableHead>
             
            </MDBTableHead>
  
            <img src={logoM}  style={{ width: 25, height: 25 }} alt="" /> Derechos reservados Marathon Group 2023 
   

          </MDBTable>

    </div>

       
    </div>
    

  )
}

const mapStatetoProps = state => {
  return {
    loggedIn: state.reducersApp.loggedIn,
  }
}


export default connect(mapStatetoProps, null)(SearchComponent)