import React,{useState, useEffect} from 'react'
import Alert from 'react-bootstrap/Alert';
import  { Redirect } from 'react-router-dom'




import axios from 'axios'
import "./styles.scss"
import m from '../../../src/assets/static/agregar-carrito.svg'
import e from '../../../src/assets/static/eliminar.svg'
import imgEliminarWhite from '../../../src/assets/static/eliminar-white.svg'
import au from '../../../src/assets/static/aumentar.svg'
import dis from '../../../src/assets/static/disminuir.svg'
import logoM from '../../../src/assets/static/logo-M.svg'
import carritoWhite from '../../../src/assets/static/carrito-white.svg'
//import carritosvg from '../../../src/assets/static/compras.svg'
import d from '../../../src/assets/static/documento.svg'




import Button from '../Button'
import UserInfoComponent from '../UserInfoComponent/UserInfoComponent'
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import userImg from '../../../src/assets/static/usuario-dark.svg'
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Divider from '@mui/material/Divider';

import Stack from '@mui/material/Stack';
import { fi } from 'date-fns/locale';




//const baseUrl = "https://api-marathon-crud.azurewebsites.net/users"
 //const baseUrl = "http://localhost:3001/users"


/*
 const baseUrl = "http://localhost:3001/getProductos" 
 const baseUrl_showCart = "http://localhost:3001/showCart"
 const  baseUrl_deleteProduct= "http://localhost:3001/deleteProductCart"
 const  baseUrl_aumentarProduct= "http://localhost:3001/aumentarProductCart"
 const  baseUrl_disminuirProduct= "http://localhost:3001/disminuirProductCart"
 */
 


const baseUrl = `${process.env.REACT_APP_API}getProductos`  
 const baseUrl_showCart = `${process.env.REACT_APP_API}showCart`
 const  baseUrl_deleteProduct= `${process.env.REACT_APP_API}deleteProductCart`
 const  baseUrl_aumentarProduct= `${process.env.REACT_APP_API}aumentarProductCart`
 const  baseUrl_disminuirProduct= `${process.env.REACT_APP_API}disminuirProductCart`
 const  baseUrl_confirmPunchOut= `${process.env.REACT_APP_API}confirmPunchOut`

 const  baseUrl_OCI= `https://punchoutcommerce.com/tools/oci-roundtrip-return`
 
 

let total = 0

let mostrarAlertAdd = false



const CarritoComponent = () => {

    // componente de busqueda y  trabajar con los datos que tenemos desde json placeholder
    //const datos = obtenerDatos()
    //console.log('🎁🎁🎁los datos recibidos son:🎁🎁🎁')
    //console.log(datos)

    // setear los hooks de useState
    const [users, setUsers] = useState([])  // se inicializa como un arreglo vacio 

    
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10)


    //  hooks de búsqueda
    const [search, setSearch] = useState (" ")  // como se refiere a los estados del input vamos a inicializarlo como una cadena vacia
    const URL = "https://jsonplaceholder.typicode.com/users"
    let indiceName = []

    const ref = React.useRef(null);
    
    //funcion para traer los datos 
    const showData = async () => {
      total=0
       const dato=localStorage.getItem('catalogoId')
       console.log('🎽🎽🎽 Volvemos a hacer la consulta !!!! 🎽🎽🎽')

      
       
        console.log(`El dato a buscar es ${dato}`)

      if (!dato) {
        console.log('El dato a buscar esta vacio 🎽🎽🎽')
       setUsers([])
      } else{
        
        try {  
            //localStorage.setItem('password', password)
            const res = await axios.get (baseUrl_showCart, {params:{idcatalogo:dato}})

              
                console.log ("🎀🎀🎀 Llegue hasta aqui 🎀🎀🎀")
                console.log (" 🎀🎀🎀 length 🎀🎀🎀")
                console.log (res.data.length) 
                console.log (" 🎀🎀🎀 Datos recibidos 🎀🎀🎀")
                console.log (res.data) 
                if (res.data.length==0) {               
                    console.log (" 🎀🎀🎀 No hay datos que mostrar 🎀🎀🎀")
                    setUsers([])
                } else {
                  console.log ("🎀🎀🎀 Vamos a devolver los siguientes datos: 🎀🎀🎀")
                  console.log (res.data)

                  

                  res.data.map ( (user,indice) => (      
                    total = total + (user.cantidad * user.precio)
                  
                 ))
  
                 console.log ("El total en el carrito de compras es:")
                 console.log ( total)
                 setUsers(res.data)

                }
              
                //setUsers(response)
                
              } catch (error) {
                console.log (error)
                console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
              }
     }  

      
     
   }

   /*  ***************************************************************************    */
   /*  envia un mensaje a la API con la solicitud para borrar un prodcuto del carrito */
   /*  ***************************************************************************    */

   const deleteProductEvent = (e,arg1) => {

      setSearch(e.target.value )
      console.log ("Se dio Click en el boton Eliminar!!!")
      console.log (`El elemento a borrar es: ${arg1}`)
      console.log (e.target)
      console.log(`${arg1}`);
      localStorage.setItem('productoId',arg1) 
      mostrarAlertAdd = true

      deleteProduct(arg1)
      setSearch(e.target.value )
      console.log (e.target.value)
      //SearchComponent()
      showData(e.target.value)

   } 


    /*  ***************************************************************************                     */
   /*  envia un mensaje a la API con la solicitud para aumentar la cantidad  de un prodcuto del carrito */
   /*  ***************************************************************************                      */

   const aumentarProductEvent = (e,arg1,cantidad) => {

    setSearch(e.target.value )
    console.log ("Se dio Click en el boton aumentar!!!")
    console.log ("🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵🌵")
    console.log (`El elemento a aumentar cantidad es: ${arg1}`)
    const nuevaCantidad = cantidad+1
    mostrarAlertAdd = false
    console.log (`la nueva cantidad es: ${nuevaCantidad}`)
    //console.log (e.target)
    //console.log(`${arg1}`);
    localStorage.setItem('productoId',arg1) 

    aumentarProduct(arg1,nuevaCantidad)
    setSearch(e.target.value )
    console.log (e.target.value)
    //SearchComponent()
    showData(e.target.value)

 } 


 const disminuirProductEvent = async (e,arg1,cantidad) => {

  const producto = arg1
  setSearch(e.target.value )
  console.log ("Se dio Click en el boton aumentar!!!")
  console.log ("🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️🌵")
  console.log (`El elemento a disminuuir cantidad es: ${arg1}`)
  let nuevaCantidad = cantidad-1
  if (nuevaCantidad<1) {
    nuevaCantidad = 0

    try {
      console.log("👩‍🚒👨‍🚒El id del producto a eliminar es:")
      console.log(producto)
      const res = await axios.get (baseUrl_deleteProduct , {params:{idChP:producto}})       
         console.log ("Llegue hasta aqui 👩‍🚒👨‍🚒👩‍🚒👨‍🚒👩‍🚒👩‍🚒")
       } catch (error) {
         console.log (error)
         console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
       }
  }
  console.log (`la nueva cantidad es: ${nuevaCantidad}`)
  //console.log (e.target)
  //console.log(`${arg1}`);
  localStorage.setItem('productoId',arg1) 

  disminuirProduct(arg1,nuevaCantidad)
  setSearch(e.target.value )
  console.log (e.target.value)
  mostrarAlertAdd = false
  //SearchComponent()
  showData(e.target.value)

} 

 

 const aumentarProduct = async (producto,cantidadProducto) => {
  console.log('Volvemos a hacer la consulta !!!! 🌵🌵🌵🌵🌵🌵')
  console.log(`El producto a eliminar es ${producto}`)
  const catalogoId = localStorage.getItem('catalogoId')
 if (!producto) {
   //console.log('El dato a eliminar esta vacio ☘☘☘')
   setUsers([])

 } else{
   try {
        console.log("🌵El id del producto a aumentar es:")
        console.log(producto)
        console.log("🌵--🌵 La nueva cantidad es:")
        console.log(cantidadProducto)
        const res = await axios.get (baseUrl_aumentarProduct , {params:{idChP:producto, cantidad:cantidadProducto}})       
           console.log ("Llegue hasta aqui 🌵🌵🌵🌵")
         } catch (error) {
           console.log (error)
           console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
         }
 }  
}


const disminuirProduct = async (producto,cantidadProducto) => {
  console.log('Volvemos a hacer la consulta !!!! 🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️')
  console.log(`El producto a eliminar es ${producto}`)
  const catalogoId = localStorage.getItem('catalogoId')
 if (!producto) {
   //console.log('El dato a eliminar esta vacio ☘☘☘')
   setUsers([])

 } else{
   try {
        console.log("🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️El id del producto a aumentar es:")
        console.log(producto)
        console.log("🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️La nueva cantidad es:")
        console.log(cantidadProducto)
        const res = await axios.get (baseUrl_disminuirProduct , {params:{idChP:producto, cantidad:cantidadProducto}})       
           console.log ("Llegue hasta aqui 🤹‍♀️🤹‍♀️🤹‍♀️🤹‍♀️")
         } catch (error) {
           console.log (error)
           console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
         }
 }  
}



 const deleteProduct = async (producto) => {
      console.log('Volvemos a hacer la consulta !!!! 👩‍🚒👩‍🚒👩‍🚒👩‍🚒👩‍🚒')
      console.log(`El producto a eliminar es ${producto}`)
      const catalogoId = localStorage.getItem('catalogoId')
     if (!producto) {
       //console.log('El dato a eliminar esta vacio ☘☘☘')
       setUsers([])

     } else{
       try {
            console.log("👩‍🚒👨‍🚒El id del producto a eliminar es:")
            console.log(producto)
            const res = await axios.get (baseUrl_deleteProduct , {params:{idChP:producto}})       
               console.log ("Llegue hasta aqui 👩‍🚒👨‍🚒👩‍🚒👨‍🚒👩‍🚒👩‍🚒")
             } catch (error) {
               console.log (error)
               console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
             }
     }  
   }


    const searcher = (e) => {
      setSearch(e.target.value )
      console.log (e.target.value)
      //SearchComponent()
      showData(e.target.value)
     } 

     const clicker = (e,arg1) => {
      setSearch(e.target.value )
      console.log ("Se dio Click en el boton!!!")
      console.log (e.target)
     
      
      //addProduct(arg1,arg2)
     } 


    // método de filtado 
  

    
  const muestraAlert= () => {
   
    console.log(" 🌴🌴🌴Entramos a muestra Alert 🌴🌴🌴")
    if (mostrarAlertAdd) {
      console.log("El valor de mostrarAlertAdd es true 🌴🌴🌴")
      return (
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
            Se eliminó un elemento del carrito
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      )
   }
    console.log("El valor de mostrarBoton es false 🌴🌴🌴")
    return (true)  
  }

    
    //funcion de búsqueda

    useEffect (() => {
      showData()

    }, [])
  //renderizar la vista mediante una tabla que mostrará los datos 

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  let contador=0 




  const mostrarBoton = () => {
  
    const compraMinima = localStorage.getItem('idmonto_min_compra')

    if (total>=compraMinima){

      return (<button className="css-button-sharp--red"  style={{marginLeft: "20re" }}> Ordenar</button>)
    } else{
      return (<button className="css-button-sharp"  style={{marginLeft: "20re" }} disabled > Ordenar</button>)
    }


  }

  const handleSubmit = async (e,arg1) => {
 


      const datos_carrito = arg1
      
      e.preventDefault();
      console.log('You clicked submit.');
      console.log("Los datos del carrito son")
      console.log(datos_carrito)
      const dato=localStorage.getItem('catalogoId')

      try {  
        //localStorage.setItem('password', password)
        const res = await axios.get (baseUrl_confirmPunchOut, {params:{id_catalogo:localStorage.getItem('catalogoId')}})

          
            console.log ("🌎🌎🌏🌎 Entramos a handleSubmit 🌍🌍🌍🌍")
    
            console.log (" 🌍🌍🌍🌍 Datos recibidos 🌍🌍🌍🌍")
            console.log (res.data) 
            if (res.data.length==0) {               
                console.log (" 🌍🌍🌍🌍 No hay datos que mostrar 🌍🌍🌍🌍")
                setUsers([])
            } else {
              console.log ("🌍🌍🌍🌍 Vamos a devolver los siguientes datos: 🌍🌍🌍🌍")
              console.log (res.data)
              console.log ("Filas afectadas: " + res.data["affectedRows"])

              if (res.data["affectedRows"]==1) {
                console.log ("La actualizacion del estado del catalogo fue exitosa")

                document.getElementById('formOCI').submit()

                /*
                const data = new FormData();
                
                data.append("otherStuff", "stuff from a text input");

                fetch(  "https://punchoutcommerce.com/tools/oci-roundtrip-return", {
                    method: "POST",
                    mode: 'no-cors',
                    body: data
                });

                */
              }
             

            }
          
            //setUsers(response)
            
          } catch (error) {
            console.log (error)
            console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
          }



    }
  
    

     
  

  return (

    <div>



      <div className='container' style={{fontSize:"12px", color:"#393939", justifyContent: 'right', alignItems: 'right', display:"flex", marginRight:"0rem", paddingRight:"0rem"}}>  

      <div     style={{textAlign:"right", paddingTop:"2rem"}}>
       <tr>
      
        <td> <p className='fw-bold mb-1'>    
                <img
                    src={userImg}
                    alt=''
                    style={{ width: '15px', height: '15px' }}
                    className='rounded-circle'
                />
                {localStorage.getItem('idnombre') } </p></td>



        <td style={{paddingRight:"2rem", paddingTop:"0rem"}}></td>
        <td style={{paddingRight:"2rem", paddingTop:"0rem"}}> <b> Empresa: { localStorage.getItem('nombre_empresa') } </b></td>
        <td> <b> Orden de Compra: { localStorage.getItem('catalogoId') }</b></td>
        <td style={{paddingLeft:"2rem"}}>  <b> Compra mínima: {localStorage.getItem('idmonto_min_compra')} {localStorage.getItem('nombre_moneda')}  </b> </td>
        </tr>  
      </div>

      <div style={{ textAlign:'center', paddingTop:20 }} >         
          <a title="Los Tejos" href= {process.env.REACT_APP_WEB+"punchout"} > <img src={d}  style={{ width: 50, height: 50 }} alt="" />
          </a>
          <div>
            <span className="badge bg-primary rounded-pill"> Seguir comprando</span>
          </div>
        </div>
      </div>
      

        


        <div style={{ textAlign:'right', paddingTop:"3rem" }} >  </div>
        <center> <img src={m}  style={{ width: 30, height: 30 }} alt="" /> </center>
        <h4  style={{ marginBottom:'4rem', marginBottom:"1rem",  paddinTop:"1rem" }}> <center>  CARRITO DE COMPRAS </center> </h4>
      
        <div style={{ marginBottom:'2rem', marginTop:"0rem",  paddinTop:"0rem" }}>
        <center><p style={{fontSize:"13px", color:"#393939", paddingBottom:"3rem"}}>  Puede modificar cantidades y eliminar productos antes de realizar el pedido </p></center>
        </div>

        {muestraAlert()}

        <div style={{paddingRight:"10rem", marginRight:"0rem", paddingLeft:"10rem"}} >
         <table className="table table-striped table-hover mt-150 shadow" style={{fontSize:"13px"}}>
            <thead >
                <tr className=''>
                    <th style={{paddingRight:"0rem", marginRight:"0rem"}}>idChP</th>  
                    <th style={{paddingRight:"0rem", marginRight:"0rem"}}>PRODUCTO</th>                    
                    <th style={{paddingRight:"0rem", marginRight:"0rem"}}>PRECIO</th>
                    <th style={{paddingRight:"0rem", marginRight:"0rem"}}>CANTIDAD</th>
                    <th style={{paddingRight:"0rem", marginRight:"0rem"}}>MOD. CANT.</th>
                    <th style={{paddingRight:"0rem", marginRight:"0rem"}}>TOTAL</th>
                    <th style={{paddingRight:"0rem", marginRight:"0rem"}}>ELIMINAR</th>                   
                </tr>
            </thead>

            <tbody > 
        
                    {users.map ( (user) => (
                       
                        <tr key={user.codigo}>
                            <td> {user.idChP}</td>
                            <td> {user.descripcion} </td>
                            <td> {user.precio} </td>                          
                            <td> {user.cantidad} </td>                       
                            <td>
                            <button 
                                  className="btn btn-warning btn-sm"                                    
                                  onClick={(event)=>aumentarProductEvent(event, user.idChP,user.cantidad )}
                               >
                                  <img src={au}  style={{ width: 15, height: 15 }} alt="" />   
                               </button>

                               <button 
                                  className="btn btn-warning btn-sm"                                    
                                  onClick={(event)=>disminuirProductEvent(event, user.idChP,user.cantidad )}
                               >

                                  <img src={dis}  style={{ width: 15, height: 15 }} alt="" />   
                               </button>
                              
                            </td>
                            <td> {(user.precio * user.cantidad).toFixed(2) } 
                               
                            </td>
                          
                            <td> <center>
                              
                               <button 
                                  className="btn btn-primary btn-sm"                                    
                                  onClick={(event)=>deleteProductEvent(event, user.idChP)}
                               >
                                  <img src={imgEliminarWhite}  style={{ width: 15, height: 15 }} alt="" />   
                               </button>
                           </center></td>
                        </tr>
                    ))}           
                </tbody>
        </table>
        </div>

      <div style={{textAlign:'right', marginLeft:'30rem', paddingRight:'10rem', paddingTop:'5rem', paddingBottom:'0rem'}}>
        <MDBTable align='middle'>
            <MDBTableHead>
              <tr>
                <th scope='col'>Totales</th>
                <th scope='col'></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              <tr>
             
                <td>Subtotal</td>
                <td>
                  {total.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>Shipping</td>
                <td>
                  0
                </td>
              </tr>
              <tr>
                           
                <td> <h5> Total </h5></td>
                <td>
                <h5>  <span style={{color: "#000", fontSize:"12px"}}>{localStorage.getItem('moneda')}  </span> {total.toFixed(2)}  </h5>
                </td>
              </tr>
            </MDBTableBody>
      </MDBTable>
      </div>

      <div>

      

          <form id='formOCI' onSubmit={(event)=>handleSubmit(event, users) } action="https://punchoutcommerce.com/tools/oci-roundtrip-return" method="POST" >     
            {users.map ( (user,indice) => (                          
                
                <div>
                  {console.log (indice)}
                  <input type="hidden" name={"NEW_ITEM-VENDORMAT[" + (indice+1) + "]"} value = {user.idChP}></input>
                  <input type="hidden" name={"NEW_ITEM-DESCRIPTION[" + (indice+1) + "]"} value = {user.short_description} />
                  <input type="hidden" name={"NEW_ITEM-QUANTITY[" + (indice+1) + "]"} value = {user.cantidad} />
                  <input type="hidden" name={"NEW_ITEM-UNIT[" + (indice+1) + "]"} value = {user.nombre_medida} ></input> 
                  <input type="hidden" name={"NEW_ITEM-PRICE[" + (indice+1) + "]"} value = {user.precio} />
                  <input type="hidden" name={"NEW_ITEM-CURRENCY[" + (indice+1) + "]"} value = {user.nombre_moneda}></input>  
                  <input type="hidden" name={"NEW_ITEM-LEADTIME[" + (indice+1) + "]"} value =  {user.leadtime}></input>
                  <input type="hidden" name={"NEW_ITEM-MANUFACTMAT["+ (indice+1) + "]"} value = {user.codigo}></input>
                  <input type="hidden" name={"NEW_ITEM-MATGROUP[" + (indice+1) + "]"} value ={user.material_group}></input> 
                </div>                
            ))} 

            <div style={{textAlign:'right', marginLeft:'30rem', paddingRight:'10rem', paddingTop:'0rem', paddingBottom:'0rem'}} >

            {mostrarBoton()}


            </div>
         </form>
    </div>







    <div style={{textAlign:'center', marginLeft:'0rem', paddingRight:'0rem', paddingTop:'2rem', marginTop:'0rem'}}>
        <MDBTable align='middle'>
            <MDBTableHead>
             
            </MDBTableHead>
  
            <img src={logoM}  style={{ width: 25, height: 25 }} alt="" /> Derechos reservados Marathon Group 2023 
   

          </MDBTable>

    </div>





 






    </div>
  )
}

export default CarritoComponent