import React,{useState, useEffect} from 'react'
import axios from 'axios'
import "./styles.scss"
import carrito from '../../../src/assets/static/carrito-compras-white.svg'
import userImg from '../../../src/assets/static/usuario-dark.svg'	
import productImg from '../../../src/assets/static/caja-abierta.svg'
//import UserInfoComponent from '../userInfoComponent/userInfoComponent'
import UserInfoComponent from '../UserInfoComponent/UserInfoComponent'
import {Link} from 'react-router-dom';	
import { useParams } from 'react-router-dom'

 const baseURL_searchOrdenCompra = `${process.env.REACT_APP_API}searchOrdenCompraPunchOut`
 const baseURL_datosIniciales = `${process.env.REACT_APP_API}datosIniciales`
 


const CrmComponent = () => {

   console.log("🔴🔴🔴🔴 ENTRAMOS AL COMPONENTE CRM 🔴🔴🔴🔴")


   const {idusername,idpassword,idid} = useParams()
  localStorage.setItem ('idusername', idusername)
  localStorage.setItem ('idpassword',idpassword)
  localStorage.setItem ('idid', idid)


  console.log ("🌕🌓🌔🌒🌕🌘🌕🌕🌕🌔🌗🌘🌒")

  console.log (` idid: ${localStorage.getItem ('idid')}`)
  console.log (`idusername: ${localStorage.getItem('idusername')}`)  
  console.log (` idpassword: ${localStorage.getItem('idpassword')}`)
  console.log (` idtoken: ${localStorage.getItem('idtoken')}`)
  console.log (`idnombre: ${localStorage.getItem('idnombre')}`)
  console.log (` idapellido_paterno ${localStorage.getItem('idapellido_paterno')}`)
  console.log (` idapellido_materno ${localStorage.getItem('idapellido_materno')}`)
  console.log (` idrole ${localStorage.getItem('idrole')}`)
  console.log (` idrole ${localStorage.getItem('idrole')}`)
  console.log (` idBranchCD ${localStorage.getItem('idBranchCD')}`)
  console.log (` iddepartment ${localStorage.getItem('iddepartment')}`)
  console.log (` idmail ${localStorage.getItem('idmail')}`)
  console.log (` idusr_punchout ${localStorage.getItem('idusr_punchout')}`)
  console.log (` idnombre_empresa ${localStorage.getItem('idnombre_empresa')}`)
  console.log (` idmoneda_monto_min ${localStorage.getItem('idmoneda_monto_min')}`)
  console.log (` idmonto_min_compra ${localStorage.getItem('idmonto_min_compra')}`)
  console.log (` idnombre_moneda ${localStorage.getItem('idnombre_moneda')}`)

  console.log ("🌕🌓🌔🌒🌕🌘🌕🌕🌕🌔🌗🌘🌒")

    // componente de busqueda y  trabajar con los datos que tenemos desde json placeholder
    //const datos = obtenerDatos()
    //console.log('🎁🎁🎁los datos recibidos son:🎁🎁🎁')
    //console.log(datos)

    // setear los hooks de useState
    const [users, setUsers] = useState([])  // se inicializa como un arreglo vacio 
    //  hooks de búsqueda
    const [search, setSearch] = useState (" ")  // como se refiere a los estados del input vamos a inicializarlo como una cadena vacia
    const URL = "https://jsonplaceholder.typicode.com/users"
    
    const addCatalogo = async () => {



      console.log("🟨🟨🟨🟨🟨 ENTRAMOS A addCatalogo 🟨🟨🟨🟨🟨")


      const usuarioId = localStorage.getItem('id') 
      console.log('Volvemos a hacer la consulta addCatalog !!!! 🍨🍨🍨')
      console.log(`El dato a buscar es ${localStorage.getItem('idid')}`)

   
        try {
            console.log('🚨🚨🚨🚨 VAMOS A BUSCAR EL CATALOGO DE COMPRA DEL USUARIO CON id: ' + usuarioId)
            //localStorage.setItem('password', password)                
            const res = await axios.get (baseURL_searchOrdenCompra ,  {params:{userId:localStorage.getItem('idid')}})          
                console.log ("Llegue hasta aqui 🍒🍒🍒")
                console.log ("Datos recibidos")
                console.log (res.data)      
                console.log ("idCatalogo_compra ")
                console.log (res.data[0].idcatalogo_compra)                                            
                console.log ("cliente ")
                console.log (res.data[0].cliente)
                console.log ("hasproductos")
                console.log (res.data[0].hasproductos)       
                console.log ("estado")
                console.log (res.data[0].estado)                                 
                console.log (" 🧟‍♂️🧟‍♂️🧟‍♂️  Vamos a devolver los siguientes datos: 🧟‍♂️🧟‍♂️🧟‍♂️")
                localStorage.setItem('catalogoId', res.data[0].idcatalogo_compra)                
                localStorage.setItem('moneda', res.data[0].nombre_moneda)
                console.log("El id del cartago de compra es:")
                console.log (localStorage.getItem('catalogoId'))

                console.log (res.data)
               
                //setUsers(response)                  
              } catch (error) {
                console.log (error)
                console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
              }
              try {
  
                //localStorage.setItem('password', password)      
                const res = await axios.post (baseURL_datosIniciales, {params:{USERNAME:idusername,PASSWORD:idpassword}})
            
                    console.log ("🚛🚛🚛🚛Llegue hasta aqui 🚛🚛🚛🚛")
                    console.log ("Datos recibidos")
                    console.log (res.data)              
                    localStorage.setItem ('idtoken', res.data[0].token)
                    localStorage.setItem ('idnombre', res.data[0].nombre)
                    localStorage.setItem ('idapellido_paterno',res.data[0].apellido_paterno)
                    localStorage.setItem ('idapellido_materno',res.data[0].apellido_materno)
                    localStorage.setItem ('idid', res.data[0].id)
                    localStorage.setItem ('idrole', res.data[0].role)
                    localStorage.setItem ('idBranchCD', res.data[0].BranchCD)  
                    localStorage.setItem ('iddepartment',res.data[0].department)
                    localStorage.setItem ('idmail', res.data[0].mail)
                    localStorage.setItem ('idusr_punchout', res.data[0].usr_punchout)
                    localStorage.setItem ('idnombre_empresa',res.data[0].idEmpresas)
                    localStorage.setItem ('idmoneda_monto_min', res.data[0]. moneda_monto_min)
                    localStorage.setItem ('idmonto_min_compra', res.data[0].monto_min_compra)
                    localStorage.setItem ('idnombre_moneda', res.data[0].nombre_moneda)
                    
                  } catch (error) {
                    console.log (error)
                    console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
                  }
      
      }



    //funcion para traer los datos 
    const showData = async (dato) => {
       console.log('Volvemos a hacer la consulta !!!! 🥩🥩🥩🥩')
       
        console.log(`El dato a buscar es ${dato}`)

        if (!dato) {
          console.log('El dato a buscar esta vacio 🍚🍚🍚')
          setUsers([])
        } else{
          
          try {
  
            //localStorage.setItem('password', password)      
            const res = await axios.post (baseURL_datosIniciales, {params:{USERNAME:idusername,PASSWORD:idpassword}})
        
                console.log ("Llegue hasta aqui 👲👲👲")
                console.log ("Datos recibidos")
                console.log (res.data)              
              
                //setUsers(response)
                
              } catch (error) {
                console.log (error)
                console.log ("Error en el proceso!!! 🏮🏮🏮🏮🏮")
              }
      }  
    }

    const searcher = (e) => {
      setSearch(e.target.value )
      console.log (e.target.value)
      //SearchComponent()
      //showData(e.target.value)
     } 

      // método de filtado 
      //funcion de búsqueda

      useEffect (() => {
      showData()
      addCatalogo()
    }, [])
  //renderizar la vista mediante una tabla que mostrará los datos 


  return (

    <div >
        <div>
            <h1> <center>  BIENVENIDO  </center> </h1>           
        </div>   
    
        <div  style={{paddingBottom: "5rem", marginRight:'auto', display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        }}>
            <div><UserInfoComponent/></div>
            
           

        </div>
        
      
        <div  style={{ paddingTop:120,paddingBottom:120, marginRight:0, marginLeft:0, backgroundColor:'#FFF',  display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', }}>
            
            <Link to="/punchout">
                <div  className='rounded-circle' style={{ width: 60, height: 60, paddingTop:15, marginLeft:30, marginRight:30, backgroundColor:'#E3E4FF' }}>
                    <center> <img src={carrito} style={{ width: 30, height: 30 }} alt="" /></center>
                    <p style={{ color:'#384A57', paddingTop:25 }} > Productos </p>
                     
                </div>
            </Link>
       
           
        </div>
        
        <div style={{ marginBottom:'5rem', paddingTop:330 }}>
        <center><p> Derechos reservados Marathongroup 2023 </p></center>
        </div>
  
   
    </div>
  )
}

export default CrmComponent