import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import CrmComponent from '../../components/CrmComponent/CrmComponent'
import UserInfoComponent from '../../components/UserInfoComponent/UserInfoComponent'
//import SearchComponent from '../../components/SearchComponent/SearchComponent'

import './styles.scss' 


const Crm = props => {
  const [user, setUser] = useState({})


  
  // se llama cuando se realiza un cambio en el campo de texto que contiene el nombre de usuario

 
  
  return (
    <div className="crm">
    <div>
   
     </div>
     <div>
     <CrmComponent/>
    
     </div>
    </div>
  )
}

const mapDispatchToProps = {
  setUser,
}

export default connect(null, mapDispatchToProps)(Crm)
