import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import RutaComponent from '../../components/RutaComponent/RutaComponent'
//import SearchComponent from '../../components/SearchComponent/SearchComponent'

import './styles.scss' 


const Ruta = props => {
  const [user, setUser] = useState({})


  
  // se llama cuando se realiza un cambio en el campo de texto que contiene el nombre de usuario

 
  
  return (
    <div className="ruta">

    
      <div></div>
      <div> <RutaComponent/></div>
      <div></div>
      
     
    </div>
   
  )
}

const mapDispatchToProps = {
  setUser,
}

export default connect(null, mapDispatchToProps)(Ruta)
