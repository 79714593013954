import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import CarritoComponent from '../../components/CarritoComponent/CarritoComponent'
//import SearchComponent from '../../components/SearchComponent/SearchComponent'

import './styles.scss' 


const Carrito = props => {
  const [user, setUser] = useState({})


  
  // se llama cuando se realiza un cambio en el campo de texto que contiene el nombre de usuario

 
  
  return (
    <div className="Home">
    <div>
   
     </div>
     <div>
     <CarritoComponent/>
     </div>

                  
   


 
    </div>
  )
}

const mapDispatchToProps = {
  setUser,
}

export default connect(null, mapDispatchToProps)(Carrito)
